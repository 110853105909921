@tailwind base;

:root {
  /* tailwind font rem values depend on it */
  font-size: 14px;
}

html,
body {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

// this enables parallax effect (and it doesnt work on iOS safari)
@supports not (-webkit-touch-callout: none) {
  /* CSS for other than iOS devices */ 
  .my-hero {
    background-attachment: fixed;
  }
}

// @media all {
// }

@tailwind components;
@tailwind utilities;

/* MY UTILITIES */
$headerHeight: 62px;

.my-w-80 {
  width: 80%;
}

.my-header-height {
  height: $headerHeight;
}

.my-header-margin {
  margin-top: $headerHeight
}

.my-tap-highlight {
  -webkit-tap-highlight-color: #234e5252;
}
